export const VOIP_PATH = '/voip/'
export const FAX_PATH = '/fax/'
export const SMS_PATH = '/sms/'
export const ABOUT_PATH = '/chisiamo/'
export const CONTACT_PATH = '/contatti/'
export const RATES_PATH = '/tariffe/'
export const WS_PATH = '/wholesale/'
export const RECHARGE_PATH = '/ricarica/'
export const USER_PATH = '/utente/'
export const GLOSSARY_PATH = '/glossario/'

export const HELP_CENTER_URL = 'https://helpcenter.messagenet.com/'
export const CAREERS_URL = 'https://careers.messagenet.com/'
export const LINKEDIN_URL = 'https://www.linkedin.com/company/messagenet-com/'
export const FACEBOOK_URL = 'https://www.facebook.com/messagenet'
export const TWITTER_URL = 'https://twitter.com/Messagenet_it'

export const MENU_TYPE_LIGHT = 'light'
export const MENU_TYPE_DARK = 'dark'

export const ARTICLE_PARAGRAPH = 'ComponentLyberBlogArticleParagraph'
export const CALL_TO_ACTION = 'ComponentLyberBlogCallToAction'
export const PICTURE = 'ComponentLyberBlogPicture'
export const TABLE = 'ComponentLyberBlogTable'
export const FOOTNOTE = 'ComponentLyberBlogArticleFootNote'

export const PARAGRAPH_PREFIX = 'paragraph-'

export const META_DESCRIPTION = 'Scopri il blog di MessageNet: non perdere le ultime novità dal mondo del VoIP e del settore tecnologico!'